
















import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<PaymentOptions>({
  name: 'PaymentOptions'
})
export class PaymentOptions extends Vue {
  @Prop({ type: Array, required: true })
  public icons!: string[]
}

export default PaymentOptions
